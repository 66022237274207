import React, {createContext, useContext, useState} from 'react';
import defaultConfig from './defaultConfig';
import PropTypes from 'prop-types';

// @ts-ignore
const Sidebar = createContext();
// @ts-ignore
const SidebarActions = createContext();

export const useSidebar = () => useContext(Sidebar);

export const useSidebarActions = () => useContext(SidebarActions);

const SidebarProvider = ({children}: any) => {
  const [menuStyle, updateMenuStyle] = useState(
    defaultConfig.sidebar.menuStyle,
  );
  const [sidebarColorSet, updateSidebarColorSet] = useState(
    defaultConfig.sidebar.colorSet,
  );
  const [isSidebarBgImage, setSidebarBgImage] = useState(
    defaultConfig.sidebar.isSidebarBgImage,
  );
  const [sidebarBgImage, updateSidebarBgImage] = useState(
    defaultConfig.sidebar.sidebarBgImage,
  );
  const [sidebarMenuSelectedColor, updateSidebarMenuSelectedColor] = useState(
    defaultConfig.sidebar.sidebarMenuSelectedColor,
  );

  return (
    <Sidebar.Provider
      value={{
        menuStyle,
        sidebarColorSet,
        isSidebarBgImage,
        sidebarBgImage,
        sidebarMenuSelectedColor,
      }}>
      <SidebarActions.Provider
        value={{
          updateMenuStyle,
          updateSidebarColorSet,
          setSidebarBgImage,
          updateSidebarBgImage,
          updateSidebarMenuSelectedColor,
        }}>
        {children}
      </SidebarActions.Provider>
    </Sidebar.Provider>
  );
};

export default SidebarProvider;

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
