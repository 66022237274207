import React from 'react';
import {Layout, Row, Col} from 'antd';
import './AppFooter.style.less';
import {useLayout} from '../../../utility/AppProvider/LayoutProvider';
import {useIntl} from 'react-intl';

const {Footer} = Layout;

const AppFooter = () => {
  const {footer}: any = useLayout();
  const {messages} = useIntl();
  let year: any = new Date().getFullYear();

  if (footer) {
    return (
      <Footer className='app-main-footer'>
        <Row className={'row'} justify={'center'} align={'middle'}>
          <Col>
            <a href='#' target='_blank' rel='noopener noreferrer'>
              {`${messages['footer.copyright'].toString()} ${year} ${messages['websiteName'].toString()}, ${messages['footer.allRightsReserved'].toString()}`}
            </a>
          </Col>
        </Row>
      </Footer>
    );
  }
  return null;
};

export default AppFooter;
