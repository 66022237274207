import {AiOutlineLock, AiOutlineTeam} from 'react-icons/ai';
import {FaUsers} from 'react-icons/fa';
import {
  ManageCaregivers,
  ManageRoles,
  ManageUser,
} from 'src/shared/constants/permissions-enum';

const routesConfig = [
  {
    id: 'caregivers',
    title: 'caregivers',
    messageId: 'caregivers.sideBarName',
    icon: <FaUsers />,
    type: 'item',
    path: '/caregivers',
    // permissionKey: ManageCaregivers.List,
  },
  {
    id: 'roles',
    title: 'roles',
    messageId: 'rolesManagement.sideBarName',
    icon: <AiOutlineLock />,
    type: 'item',
    path: '/roles',
    permissionKey: ManageRoles.List,
  },
  {
    id: 'users',
    title: 'users',
    messageId: 'usersManagement.sideBarName',
    icon: <AiOutlineTeam />,
    type: 'item',
    path: '/users',
    permissionKey: ManageUser.List,
  },
];
export default routesConfig;
