import urlJoin from 'url-join';

export const initialUrl = '/'; // this url will open after login
export const AUTH_TOKEN: string = 'app-auth-token-admin';
export const REFRESH_TOKEN_KEY = 'app-refresh-token-admin';
export const TOKEN_EXPIRE_DATE = 'app-auth-expire-admin';
export const USER_INFO: string = 'user-info';
export const COUNT_NOTIFICATIONS: string = 'count-notifications';
export const MAX_AGE = 60 * 60 * 24 * 1;
export const Default_Language: string = 'app-language';
export const FCM_TOKEN_KEY = 'fcm-token';
export const LANGUAGE_HEADER_KEY = 'language-key';
export const PERMISSIONS_KEY = 'permissions';

export const PrimaryColor = '#21aa95';
export const SidebarColor = '#fff';
export const SidebarBackgroundColor = '#fff';
export const UPLOAD_ICON_VALIDATE = [
  'image/svg+xml',
  'image/vnd.microsoft.icon',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/webp',
];

export const formatDateTime: string = 'YYYY-MM-DD hh:mm A';
export const formatDateWithSecondsTime: string = 'YYYY-MM-DD hh:mm:ss A';
export const formatDate: string = 'MMM D, YYYY';
export const formatTimeWithMonthAndDay: string = 'MMM DD hh:mm a';
export const formatTime: string = 'hh:mm a';

export const passwordRegex: any =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{12,}$/;

export const emailRegex: any =
  /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const countryCodeDefault = '+961';

export const remoteServiceBaseUrl = process.env.REACT_APP_PUBLIC_BASE;

export const remoteServiceBaseApiUrl = urlJoin(
  `${process.env.REACT_APP_PUBLIC_API_BASE}`,
);

export const remoteSocketIoUrl = process.env.REACT_APP_REMOTE_SOCKETIO_BASE_URL;

export const remoteServiceBaseDomain = process.env.REACT_APP_PUBLIC_DOMAIN;

export const remoteServiceBaseUrlFile = process.env.NEXT_PUBLIC_BASE_URL_FILE;

export const PRODUCT_NUMBER_IN_ONE_PAGE = 10;
export const PAGE_NUMBER = 1;
export const DISTANCE_DEFAULT = 10;

export const apiKeyGoogleMap =
  'AIzaSyC_MPgcB-GAIUYap_caF_lQdB1UqFIEhMg&callback=initMap&v=weekly';

export const INDEX_PAGE_SIZE_DEFAULT = 10;
export const INDEX_PAGE_SIZE_OPTIONS = ['10', '20', '30', '40'];

export const UPLOAD_PICTURE_VALIDATE = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/webp',
];
export const UPLOAD_FILE_VALIDATE = ['text/plain', 'application/pdf'];

export const CUSTOMER_ROLES = {
  Administrators: 'Administrators',
};

export const FilterOperators = {
  Equals: '==',
  NotEquals: '!=',
  Contains: '@=',
  StringContains: '@=*',
};
