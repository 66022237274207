import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ConfigProvider} from 'antd';
import AppLocale from '../../../shared/localization';
import {useLayout} from '../AppProvider/LayoutProvider';
import {useLocale} from '../AppProvider/LocaleProvider';

const AppThemeProvider = (props: any) => {
  const {direction}: any = useLayout();
  const {locale}: any = useLocale();

  const {antLocale} = AppLocale[locale.code.toLowerCase()];

  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  return (
    <ConfigProvider direction={direction} locale={antLocale}>
      {props.children}
    </ConfigProvider>
  );
};

export default React.memo(AppThemeProvider);

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
