import React, {createContext, useContext, useState} from 'react';
import defaultConfig from './defaultConfig';
import PropTypes from 'prop-types';

// @ts-ignore
const Locale = createContext();
// @ts-ignore
const LocaleActions = createContext();

export const useLocale = () => useContext(Locale);

export const useLocaleActions = () => useContext(LocaleActions);

const LocaleProvider = ({children}: any) => {
  const [locale, updateLocale] = useState(defaultConfig.locale);

  return (
    <Locale.Provider
      value={{
        locale,
        rtlLocale: defaultConfig.rtlLocale,
      }}>
      <LocaleActions.Provider
        value={{
          updateLocale,
        }}>
        {children}
      </LocaleActions.Provider>
    </Locale.Provider>
  );
};

export default LocaleProvider;

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
