export enum ManageRoles {
  List = 'READ_ROLES_LIST',
  Details = 'READ_ROLE_DETAILS',
  Create = 'CREATE_ROLE',
  Update = 'UPDATE_ROLE',
  Delete = 'DELETE_ROLE',
  Select = 'SELECT_ROLES',
}

export enum ManagePermissions {
  List = 'READ_PERMISSIONS_LIST',
  Details = 'READ_PERMISSION_DETAILS',
  Update = 'UPDATE_PERMISSION',
}

export enum ManageUser {
  List = 'READ_USERS_LIST',
  Create = 'CREATE_USER',
  CreateAdmin = 'CREATE_ADMIN',
  UpdateUserProfile = 'UPDATE_USER_PROFILE',
  UpdateUserRole = 'UPDATE_USER_ROLE',
  Profile = 'READ_USER_PROFILE',
  Delete = 'DELETE_USER',
  Select = 'SELECT_USERS',
  ActiveAndDeactive = 'ACTIVATE_/_DEACTIVATE_USER',
}

export enum ManageCaregivers {
  List = 'READ_CAREGIVERS_LIST',
  Details = 'READ_CAREGIVER_DETAILS',
  Update = 'UPDATE_CAREGIVER',
  Delete = 'DELETE_CAREGIVER',
}
