import React, {createContext, useContext, useState} from 'react';
import defaultConfig from './defaultConfig';
import PropTypes from 'prop-types';

// @ts-ignore
const Theme = createContext();
// @ts-ignore
const ThemeActions = createContext();

export const useTheme = () => useContext(Theme);

export const useThemeActions = () => useContext(ThemeActions);

const ThemeProvider = ({children}: any) => {
  const [themeMode, updateThemeMode] = useState(defaultConfig.themeMode);

  return (
    <Theme.Provider
      value={{
        themeMode,
      }}>
      <ThemeActions.Provider
        value={{
          updateThemeMode,
        }}>
        {children}
      </ThemeActions.Provider>
    </Theme.Provider>
  );
};

export default ThemeProvider;

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
