import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import Error403 from './errorPages/Error403';
import React from 'react';
import Error404 from './errorPages/Error404';

// Roles Management
const RolesManagement = React.lazy(() => import('./roles-management'));
const CreateOrUpdateRole = React.lazy(
  () => import('./roles-management/create-or-update'),
);
const RoleDetails = React.lazy(() => import('./roles-management/role-details'));

// Users Management
const UsersManagement = React.lazy(() => import('./users-management'));
const UserDetails = React.lazy(() => import('./users-management/user-details'));
const CreateOrUpdateUser = React.lazy(
  () => import('./users-management/create-or-update'),
);

// Caregivers
const Caregivers = React.lazy(() => import('./caregivers'));

const authorizedStructure = {
  fallbackPath: '/sign-in',
  unAuthorizedComponent: <Error403 />,
  routes: [
    {
      path: '/roles',
      element: <RolesManagement />,
    },
    {
      path: '/roles/create-role',
      element: <CreateOrUpdateRole />,
    },
    {
      path: '/roles/update-role/:id',
      element: <CreateOrUpdateRole />,
    },
    {
      path: '/roles/role-details/:id',
      element: <RoleDetails />,
    },
    {
      path: '/users',
      element: <UsersManagement />,
    },
    {
      path: '/users/user-details/:id',
      element: <UserDetails />,
    },
    {
      path: '/users/create-user',
      element: <CreateOrUpdateUser />,
    },
    {
      path: '/users/update-user/:id',
      element: <CreateOrUpdateUser />,
    },
    {
      path: '/caregivers',
      element: <Caregivers />,
    },
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
