import {combineReducers} from 'redux';
import authSlice from './auth/auth-slice';
import dataGridSlice from './data-grid/data-grid-slice';
import rolesManagementSlice from './roles-management/roles-management-slice';
import usersManagementSlice from './users-management/users-management-slice';
import googleMapSlice from './google-map/google-map-slice';
import notificationSlice from './notifications/notification-slice';
import caregiversSlice from './caregivers/caregivers-slice';

export let reducer = combineReducers({
  auth: authSlice,
  dataGrid: dataGridSlice,
  rolesManagement: rolesManagementSlice,
  usersManagement: usersManagementSlice,
  googleMap: googleMapSlice,
  notifications: notificationSlice,
  caregivers: caregiversSlice,
});
