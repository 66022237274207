import {createSlice} from '@reduxjs/toolkit';

interface CaregiversInitialState {
  caregiverDetails: any | undefined;
}

const initialState: CaregiversInitialState = {
  caregiverDetails: undefined,
};

const caregiversSlice = createSlice({
  name: 'caregivers',
  initialState: initialState,
  reducers: {
    setCaregiverDetails: (state, action) => {
      state.caregiverDetails = action.payload;
    },
  },
});

export const {setCaregiverDetails} = caregiversSlice.actions;

export default caregiversSlice.reducer;
