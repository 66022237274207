import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from './ThemeProvider';
import LocaleProvider from './LocaleProvider';
import LayoutProvider from './LayoutProvider';
import SidebarProvider from './SidebarProvider';
import BreadcrumbProvider from './BreadcrumbProvider';

const AppProvider = ({children}: any) => {
  return (
    <ThemeProvider>
      <BreadcrumbProvider>
        <LocaleProvider>
          <LayoutProvider>
            <SidebarProvider>{children}</SidebarProvider>
          </LayoutProvider>
        </LocaleProvider>
      </BreadcrumbProvider>
    </ThemeProvider>
  );
};

export default AppProvider;

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
