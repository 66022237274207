import React, {createContext, useContext, useState} from 'react';
import defaultConfig from './defaultConfig';
import PropTypes from 'prop-types';

// @ts-ignore
const Layout = createContext();
// @ts-ignore
const LayoutActions = createContext();

export const useLayout = () => useContext(Layout);

export const useLayoutActions = () => useContext(LayoutActions);

const LayoutProvider = ({children}: any) => {
  const [layoutType, updateLayoutType] = useState(defaultConfig.layoutType);
  const [navStyle, updateNavStyle] = useState(defaultConfig.navStyle);
  const [direction, updateDirection] = useState(defaultConfig.direction);
  const [footerType, setFooterType] = useState(defaultConfig.footerType);
  const [footer, setFooter] = useState(defaultConfig.footer);

  return (
    <Layout.Provider
      value={{
        navStyle,
        direction,
        footerType,
        footer,
        layoutType,
        rtlLocale: defaultConfig.rtlLocale,
      }}>
      <LayoutActions.Provider
        value={{
          setFooter,
          setFooterType,
          updateDirection,
          updateNavStyle,
          updateLayoutType,
        }}>
        {children}
      </LayoutActions.Provider>
    </Layout.Provider>
  );
};

export default LayoutProvider;

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
