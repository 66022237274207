import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

// @ts-ignore
const Breadcrumb = createContext();
export const useBreadcrumb = () => useContext(Breadcrumb);
type props = {
  text: string;
  url: string;
  render?: any;
};
const BreadcrumbProvider = ({children}: any) => {
  const [breadcrumb, setBreadcrumb] = useState<Array<props>>([]);

  return (
    <Breadcrumb.Provider
      value={{
        breadcrumb,
        setBreadcrumb,
      }}>
      {children}
    </Breadcrumb.Provider>
  );
};

export default BreadcrumbProvider;

BreadcrumbProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
