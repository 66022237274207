import {createSlice} from '@reduxjs/toolkit';
import {UserDeatilsDto} from 'src/shared/services/users-management/dto/details-user-dto';

interface PositionGoogleMapProps {
  lat: undefined;
  lng: undefined;
}
interface GoogleMapInitialState {
  positionMarker: PositionGoogleMapProps | undefined;
}

const initialState: GoogleMapInitialState = {
  positionMarker: undefined,
};

const googleMapSlice = createSlice({
  name: 'googleMap',
  initialState: initialState,
  reducers: {
    setPositionInMap: (state, action) => {
      state.positionMarker = action.payload;
    },
  },
});

export const {setPositionInMap} = googleMapSlice.actions;

export default googleMapSlice.reducer;
